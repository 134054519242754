<template>
  <vx-card class="security-setting-page main-box md:w-1/2 top-zero-radius">
    <vs-row>
      <vs-col class="w-1/2">
        مدت زمان خروج اجباری در صورت نداشتن فعالیت
      </vs-col>

      <vs-col class="w-1/2">
        <vs-input class="inline-block mr-3"
                  v-model="expireTime"
                  style="width: 70px"/>
        <span class="w-auto inline-block">دقیقه</span>
      </vs-col>
    </vs-row>

    <vs-button id="saveSecuritySettingBTN" class="useral-action-button"/>
  </vx-card>
</template>

<script>
export default {
  name: 'securitySetting',
  data () {
    return {
      expireTime: 100,
      actions: {
        toolbar: [
          {
            id: 'saveSecuritySettingBTN',
            icon: 'icon-check',
            iconPack: 'feather',
            color: 'success'
          }
        ],
        leftToolbar: [
          {
            id: {name: 'Settings'},
            type: 'link',
            icon: 'CHEVRON_LEFT',
            iconPack: 'useral'
          }
        ]
      }
    }
  },
  created () {
    this.$nextTick(() => {
      this.$store.dispatch('updateNavbarActions', this.actions)
      this.$store.dispatch('updateContentNavbarStyle', 'sticky')
      this.$store.dispatch('updateContentNavbarClass', 'md:w-1/2 mx-auto')
    })
  }
}
</script>

<style lang="scss">
.security-setting-page {

  * {
    line-height: 40px;
  }

  input {
    direction: rtl;
  }

}
</style>
